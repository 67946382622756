<template>
    <ErrorRenderer v-if="capturedError" />

    <NumberInput v-else-if="_format" :ref="setElRef" :modelValue="computedModelValue" @keyup="handleKeyUp"
        @keydown="handleKeyDown" @blur="onBlur" v-bind="computedNumberInputBinding"
        @input:modelValue="val => computedModelValue = val" />

    <input v-else type="text" v-model="computedModelValue" :ref="setElRef" @blur="onBlur">
</template>


<script lang="ts">
import type { DataColumn } from 'o365-datagrid';

export interface IProps {
    modelValue?: number | string | null,
    column?: DataColumn,
    format?: string,
    returnAsString?: boolean
};
</script>

<script setup lang="ts">
import { component as NumberInput } from 'vue-number-format';
import { numberUtils } from 'o365-utils';
import { useErrorCapture } from 'o365-vue-utils';
import { userSession } from 'o365-modules';
import { ref, computed, watch } from 'vue';

const props = defineProps<IProps>();

const emit = defineEmits<{
    (e: 'update:modelValue', pValue: any)
}>();

const elRef = ref(null);

const internalValue = ref(null);
const oldInternalValue = ref(null);


const isAtDecimalEnd = ref(false);

let lastValueWasInvalid = false;
function parseDecimals(value, index) {
    var splitDecimal = value.split(userSession.decimalSeparator ?? '.')

    if (splitDecimal.length > 1) {
        var substring = splitDecimal[1].substring(0, index)
        var combinedString = `${splitDecimal[0].replace(/\s+/g, '')}.${substring.replace(/\D/g, '')}`;

        return parseFloat(combinedString);
    } else if (index != 0) {
        if (splitDecimal[0].length > index) {
            if (splitDecimal[0].includes('-0')) {
                var combinedString = splitDecimal[0].slice(0, 2) + '.' + splitDecimal[0][splitDecimal[0].length - 1];
                return parseFloat(combinedString);
            }
            if (oldInternalValue?.value && oldInternalValue?.value.includes(userSession.decimalSeparator ?? '.')) {
                var substring = splitDecimal[0].slice(-index).substring(0, index)
                var removed = splitDecimal[0].slice(0, -index);
                var combinedString = `${removed.replace(/\s+/g, '')}.${substring.replace(/\D/g, '')}`;

                return parseFloat(combinedString);
            } else {

                var zeros = '';
                for (let i = 0; i < index; i++) {
                    zeros += "0";
                }
                var combinedString = `${splitDecimal[0].replace(/\s+/g, '')}.${zeros.replace(/\D/g, '')}`;
                return parseFloat(combinedString);
            }

        } else {
            return parseFloat(splitDecimal[0].replace(/\s+/g, ''));

        }



    }
    return parseFloat(value.replace(/\s+/g, ''));
}

const disableNumberInputBinding = ref(false);
const computedNumberInputBinding = computed(() => { return disableNumberInputBinding.value ? null : numberOptions.value })

const computedModelValue = computed({
    get() {
        return internalValue.value ?? '';
    },
    set(newValue) {
        lastValueWasInvalid = false;
        if (newValue == null || (_format.value && newValue === '')) {
            internalValue.value = null;
            emit('update:modelValue', null);
        } else if (newValue !== '') {
            try {
                if (typeof newValue === 'string' && newValue.includes(',')) { newValue = newValue.replace(',', '.'); }
                let parsed = _format.value
                    ? parseDecimals(elRef.value.maskedValue, elRef.value.precision)
                    : parseFloat(newValue);
                if (Number.isNaN(parsed)) {
                    lastValueWasInvalid = true;
                    return;
                }
                if (_format.value && numberOptions.value.rawValueDivisor) {
                    parsed = parsed / numberOptions.value.rawValueDivisor;
                }
                setInternalValue(parsed);

                emit('update:modelValue', props.returnAsString ? (parsed == null ? null : `${parsed}`) : parsed);
            } catch (ex) {
                lastValueWasInvalid = true;
            }
        } else {
            lastValueWasInvalid = true;
        }
    }
});

const numberOptions = computed(() => {
    return numberUtils.getVueNumberFormatOptions(_format.value);
})

const _format = computed(() => {
    return props.format ?? props.column?.format;
});

function handleKeyUp() {
    const elementValueLenght = elRef.value.$el.value.length;
    const elementStart = elRef.value.$el.selectionStart;

    if (elRef.value.$el.value.includes("-0") && elementValueLenght != elementStart && elementValueLenght != 0) {
        elRef.value.$el.selectionStart = elementValueLenght - 1
    }
}
function handleKeyDown(e) {
    disableNumberInputBinding.value = false;
    if (e.key == '-' && computedModelValue.value == '') {
        disableNumberInputBinding.value = true;
    }

    if (!_format.value || !numberOptions.value) {
        return;
    }
    if (!numberOptions.value.decimal) {
        return;
    }
    const el = elRef.value.$el;
    const separatorIndex = el.value.indexOf(numberOptions.value.decimal) + 1;
    if (separatorIndex !== -1 && separatorIndex !== 0) {
        isAtDecimalEnd.value = el.selectionStart > separatorIndex && el.value.substring(separatorIndex).length - (numberOptions.value.suffix ? 1 : 0) >= numberOptions.value.precision;
    }
    if (e.key !== "." && e.key !== ",") {
        if (el.selectionStart > separatorIndex && !isNaN(parseInt(e.key)) && e.key !== " " && !isAtDecimalEnd.value) {
            // e.target.value = e.target.value.substring(0, el.selectionStart) + e.key + e.target.value.substring(el.selectionStart + 5)
            // e.stopImmediatePropagation();
            // e.preventDefault();
            // const inputEvent = new Event('input', {
            // bubbles: true,
            // cancelable: true,
            // target: e.target,
            // });
            // e.target.dispatchEvent(inputEvent);
        }

        return;
    } else if (separatorIndex == 0) {
        return;
    } else {
        el.selectionStart = separatorIndex;
    }
}

function isValidElValue() {
    if (_format.value) {
        return true;
    } else {
        return elRef.value && (elRef.value.valueAsNumber || elRef.value.valueAsNumber === 0);
    }
}

function onBlur() {

    if (lastValueWasInvalid) {
        if (!isValidElValue()) {
            internalValue.value = null;
            emit('update:modelValue', null);
            if (!_format.value && elRef.value) {
                elRef.value.value = '';
            }
        } else {
            setInternalValue(props.modelValue);
        }
    }
}

function activateEditor(pOptions) {
    const el = elRef.value?.$el ? elRef.value.$el : elRef.value;
    el?.focus();
    if (el && props.modelValue && `${props.modelValue}`.length == 1 && _format.value) {
        el.setSelectionRange(1, 1);
        return;
    }
    if (el && pOptions?.selectionRange) {
        el.setSelectionRange(pOptions.selectionRange[0], pOptions.selectionRange[1])
        el.scrollLeft = 0;
        return;
    }
    if (el && el.value && numberOptions.value && numberOptions.value.decimal && el.value.indexOf(numberOptions.value.decimal) > -1) {
        const vStart = el.value.indexOf(numberOptions.value.decimal);
        el.setSelectionRange(vStart, vStart);
    }
}

// function activateEditor() {
// const el = elRef.value?.$el ? elRef.value.$el : elRef.value;
// el.focus();
// if (el && props.modelValue && `${props.modelValue}`.length == 1 && _format.value) {
// el.setSelectionRange(1, 1);
// return;
// }
// if(el && el.value && numberOptions.value && numberOptions.value.decimal && el.value.indexOf(numberOptions.value.decimal) > -1){
// const vStart = el.value.indexOf(numberOptions.value.decimal);
// el.setSelectionRange(vStart, vStart);
// }
// }

function setElRef(el) {
    elRef.value = el;
}

function setInternalValue(value) {
    if (value && _format.value && numberOptions.value.rawValueDivisor) {
        internalValue.value = value * numberOptions.value.rawValueDivisor
    } else {
        if (value && !(value?.toString().includes('-0') && value?.toString().length < 3)) {
            internalValue.value = value
        }
        if (internalValue.value === value) {
            if (elRef.value && elRef.value.value != value) {
                elRef.value.value = value;
            }
        } 
    }
    oldInternalValue.value = internalValue.value
}

setInternalValue(props.modelValue);
watch(() => props.modelValue, (newValue) => {
    setInternalValue(newValue);
});

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render NumberEditor`,
    errorRenderFunctionOptions: {
        uiMessage: 'NumberEditor Render Error',
        uiTitleMessage: 'An error has occurred when rendering this NumberEditor',
        type: 'span'
    }
});

defineExpose({ elRef, activateEditor });
</script>